
















































import Vue from "vue";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";

@Component
export default class Companies extends Vue {
  loading = false;
  toggling = false;
  toggled = "";
  companies: any[] = [];

  isActive(company: any) {
    if (company.subscription === null) return false;
    return company.subscription.isActive;
  }

  async getCompanies() {
    this.loading = true;
    try {
      const end = "/api/Company/List";
      const res = (await api.get(end)) as any[];
      this.companies = res;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async toggle(id: string) {
    this.toggling = true;
    this.toggled = id;
    try {
      const end = `/api/Company/ToggleActive/${id}`;
      await api.patch(end);
      const c = this.companies.find(x => x.companyId === id);
      if (!c) return;
      const state = this.isActive(c);
      c.subscription = { isActive: !state };
    } catch (error) {
      console.log(error);
    }
    this.toggling = false;
    this.toggled = "";
  }

  created() {
    this.getCompanies();
  }
}
